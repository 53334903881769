li {
    position: relative;

    span {
        position: absolute;
        font-family: 'Inter', sans-serif;
        font-weight: 900;
        font-size: 96px;
        line-height: 113px;
        text-transform: uppercase;
        color: #FFFFFF;
        left: 57px;
        top: 30px;
    }
}