h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
    color: #343434;
    margin-bottom: 20px;
}

h3 {
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: #343434;
}

select {
    height: 50px;
    padding: 5px 10px;
    background: #D9D9D9;
    border: none;
    color: #231F20;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.05em;
}