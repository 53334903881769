.header {
    align-items: center;
}

h1, h4 {
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 900;
}

.custom-container {
    max-width: 1250px;
    margin: 0 auto;
}

.button-group {
    width: 30%;
}

.button-customize {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border: none;
    background-color: #D9D9D9;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    color: #000000;

    span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
    }
}

.form-wrapper {
    width: 100%;
    height: 550px;
    overflow: scroll;
    background-color: #D9D9D9;
    padding: 20px 24px;

    input, textarea, select {
        width: 100%;
        background: #B3B3B3;
        border: none;
        padding-left: 15px;
        margin-bottom: 15px;
    }

    input, select {
        height: 50px;
    }

    .select {
        padding-right: 15px;
    }

    input[type="file"] {
        padding-top: 10px;
    }

    textarea {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 100px;
    }
}

.inputListItem {
    display: flex;
    justify-content: space-between;

    div {
        height: 50px;
        width: 50px;
        background: #343434;
        color: white;
        font-size: 25px;
        font-weight: 900;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.button-wrapper {
    width: 256px;

    .submit {
        padding: 19px ;
        background-color: #D9D9D9;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #231F20;
        width: 100%;
        text-align: center;
        border: none;
    }
}

.list-item {
    height: 50px;
    width: 100%;
    background: #D9D9D9;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span, button {
        color: #231F20;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        letter-spacing: 0.05em;
        width: 18%;
    }

    button {
        background-color: transparent;
        border: none;
    }
}

.button-close {
    background-color: transparent;
    border: none;
}

.pageArrow {
    width: 100%;
    border: none;
    background-color: none;
}

.input-search-wrapper {
    max-width: 600px;
    width: 100%;
    background-color: #D9D9D9;
    padding: 12px 19px 11px 24px;
    display: flex;
    justify-content: space-between;

    input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
    }

    button {
        background-color: transparent;
        border: none;

        img {
            height: 35px;
            width: 35px;
        }
    }
}