@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #F5F5F5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-190 {
  width: 190px;
}

.btn-logout {
  position: absolute;
  right: 0;
  width: 60px;
  display: flex;
}

.loader-input {
  height: 60px;
  background-color: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-brand {
  margin: 0;
}

.block-content {
  position: absolute;
  z-index: 10000;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 367px;
  width: 100%;

  form {
    width: 100%;

    .flex {
      display: flex;
      margin: 0 auto;
    }

    .form-label {
      width: 100%;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #231F20;
      font-weight: 700;
    }

    input {
      height: 60px;
      background-color: #D9D9D9;
      border: none;
    }

    .btn-submit {
      width: 100%;
      margin: 0 auto;
      color: #231F20;
      font-weight: 700;
      font-size: 20px;
      height: 60px;
    }
  }
}

.bg-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.modal-agree {
  background-color: #D9D9D9;
  max-width: 687px;
  width: 100%;
  padding: 33px 0;
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #231F20;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #231F20;
    max-width: 490px;
    margin: 22px auto;
  }

  .button-group {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      background-color: #EAEAEA;
      border: none;
      padding: 12px 70px 13px;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #231F20;
      margin: 0 20px;
    }
  }
}
