h2 {
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    text-transform: uppercase;
    color: #343434;
}

.listOrders {

    .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: 5px 15px;

        .line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        .line:nth-child(2) {
            align-items: flex-end;

            span:nth-child(1) {
                width: 54.7%;
                padding: 5px 0px 10px;
            }

            span:nth-child(2) {
                width: 30.3%;
                padding: 5px 0px 10px;
            }

            span:nth-child(3), button {
                width: 15%;
                padding: 5px 0px 10px;
            }
        }
    }

    .button-plus, .button-min {
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
        color: #000000;
        height: 40px;
    }
}